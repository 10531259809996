import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react';
import { Client } from 'types';

const Root =styled('div')`
  img {
    display: block;
    height: 30px;
  }
`

const FFLogo = () => {
  return <>
    <Box sx={{
      display: {
        xs: 'none',
        sm: 'block'
      }
    }}>
      <img src="/logo-color.svg" />
    </Box>
    <Box sx={{
      display: {
        xs: 'block',
        sm: 'none'
      }
    }}>
      <img src="/logo-small-primary-outline.svg" style={{height: "33px"}} />
    </Box>
  </>
}

export default function Logo({
  endInsuredClient,
  style
}: {
  endInsuredClient?: Client
  style?: React.CSSProperties
}) {
  const src = endInsuredClient?.branding?.logo_file_name || "ClientLogo.png";
  return <Root>
    {endInsuredClient ? <img src={`/${src}`} style={style} /> : <FFLogo />}
  </Root>
}