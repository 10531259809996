import { ReactNode } from 'react'
import AppNav from 'component/AppNav';
import Logo from 'component/Logo';

import { styled } from '@mui/material/styles';
import { gradients } from 'theme/palette';
import { Link } from 'element';

const Root =  styled('div')(({theme}) => `
  background: ${gradients.default};
  height: 100vh;
  width: 100vw;
`)

const StyledHeader = styled('header')(({theme}) => `
  padding: ${theme.spacing(4)};
  text-align: center;
`)

const StyledMain = styled('main')(({theme}) => `
  padding: ${theme.spacing(2)};
`)


export default function PageWithoutNav({
  children
}: {children: ReactNode}) {
  return <Root>
    <StyledHeader>
      <Link href="/"><Logo /></Link>
    </StyledHeader>
    <StyledMain>
      {children}
    </StyledMain>
  </Root>
}
