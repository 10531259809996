import { CrudPathProps } from "routes/crud";
import { BankAccount, Cart, Client, EndInsured, Maybe, PastDueCorrespondence, PastDueItem } from "types";

interface ClientBankAccountPathProps {
  bank_account?: Maybe<BankAccount>  
}

export const clientBankAccountPath = (client: Client, opts?: ClientBankAccountPathProps) => {
  if (opts?.bank_account?.id) {
    return `/clients/${client.id}/bank-accounts/${opts.bank_account.id}/`;
  } else {
    return `/clients/${client.id}/bank-accounts/`;
  }
}
