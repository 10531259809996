import { CrudPathProps } from "routes/crud";
import { Client, Maybe } from "types";


export interface AdminClientCrudPathProps extends CrudPathProps {
  client?: Maybe<Client>
}

export const adminClientPath = (opts?: AdminClientCrudPathProps) => {
  if (opts?.client) {
    if (opts?.update) {
      return `/admin/clients/${opts.client.id}/edit/`;
    } else {
      return `/admin/clients/${opts.client.id}/`;
    }
  } else {
    if (opts?.create) {
      return '/admin/clients/new/';
    } else {
      return '/admin/clients/';
    }
  }
}

export const adminClientInvoicesPath = (client: Client) => {
  return `/admin/clients/${client.id}/invoices/`;
}

export const adminSetupClientPath = (client: Client) => {
  return `/admin/clients/${client.id}/setup/`;
}
