import { CrudPathProps } from "routes/crud";
import { AgencyPartner, Cart, Client, EndInsured, Maybe, PastDueCorrespondence, PastDueItem, Statement } from "types";

export interface ClientAgencyPathProps extends CrudPathProps {
  agency?: Maybe<AgencyPartner>
}

export const clientAgencyPath = (client: Client, opts?: ClientAgencyPathProps) => {
  if (opts?.agency?.id) {
    if (opts?.update) {
      return `/clients/${client.id}/agency/${opts.agency.id}/edit/`;
    } else {
      return `/clients/${client.id}/agency/${opts.agency.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/agency/new/`;
    } else {
      return `/clients/${client.id}/agency/`;
    }
  }
}

export interface ClientAgencyCartCrudPathProps extends CrudPathProps {
  cart?: Maybe<Cart>
  review?: boolean
}

export const clientAgencyCartPath = (client: Client, agency: AgencyPartner, opts?: ClientAgencyCartCrudPathProps) => {
  if (opts?.cart) {

    if (opts?.update) {
      return `/clients/${client.id}/agency/${agency.id}/carts/${opts.cart.id}/edit/`;
    } else if (opts?.review) {
      return `/clients/${client.id}/agency/${agency.id}/carts/${opts.cart.id}/review/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/carts/${opts.cart.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/agency/${agency.id}/carts/new/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/carts/`;
    }
  }
}

export interface ClientAgencyStatemntCrudPathProps extends CrudPathProps {
  statement?: Maybe<Statement>
  preview?: boolean
}

export const clientAgencyStatementPath = (client: Client, agency: AgencyPartner, opts?: ClientAgencyStatemntCrudPathProps) => {
  if (opts?.statement) {
    if (opts?.update) {
      return `/clients/${client.id}/agency/${agency.id}/statements/${opts.statement.id}/edit/`;
    } else if (opts?.preview) {
      return `/clients/${client.id}/agency/${agency.id}/statements/${opts.statement.id}/preview/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/statements/${opts.statement.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/agency/${agency.id}/statements/new/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/statements/`;
    }
  }
}


