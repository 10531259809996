import {  Client, Product, User } from "types";
import { BankAccount } from "types/banking";
import { CrudPathProps } from "../crud";

interface ClientCustomerCommunicationsPathProps {
  configurationType: string  
}

export const clientCustomerCommunicationsPath = (client: Client, opts: ClientCustomerCommunicationsPathProps) => {
  return `/clients/${client.id}/configuration/customer-communications/${opts.configurationType}/`;
}

interface ClientProductCrudPathProps extends CrudPathProps {
  product?: Product,
}

export const clientProductsPath = (client: Client, opts?: ClientProductCrudPathProps) => {
  if (opts?.product?.id) {
    if (opts?.update) {
      return `/clients/${client.id}/configuration/products/${opts.product.id}/edit/`;
    } else {
      return `/clients/${client.id}/configuration/products/${opts.product.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/configuration/products/new/`;
    } else {
      return `/clients/${client.id}/configuration/products/`;
    }
  }
}

interface ClientUserCrudPathProps extends CrudPathProps {
  user?: User,
}

export const clientUsersPath = (client: Client, opts?: ClientUserCrudPathProps) => {
  if (opts?.user?.id) {
    if (opts?.update) {
      return `/clients/${client.id}/configuration/team-security/team/${opts.user.id}/edit/`;
    } else {
      return `/clients/${client.id}/configuration/team-security/team/${opts.user.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/configuration/team-security/team/new/`;
    } else {
      return `/clients/${client.id}/configuration/team-security/team/`;
    }
  }
}

interface ClientConfigBankAccountCrudPathProps {
  bank_account?: BankAccount,
  create?: boolean
}

export const clientConfigBankAccountPath = (client: Client, opts?: ClientConfigBankAccountCrudPathProps) => {
  if (opts?.bank_account?.id) {
    return `/clients/${client.id}/configuration/banking/${opts.bank_account.id}/edit/`;
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/configuration/banking/new/`;
    } else {
      return `/clients/${client.id}/configuration/banking/`;
    }
  }
}


export const clientPaymentMethodsPath = (client: Client) => {
  return `/clients/${client.id}/configuration/payment-methods/`;
}

export const clientCustomerBillingSettingsPath = (client: Client) => {
  return `/clients/${client.id}/configuration/payment-methods/customer-billing-settings`;
}

export const clientPaymentProcessorPath = (client: Client) => {
  return `/clients/${client.id}/configuration/payment-processor/`;
}
