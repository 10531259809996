export const loginPath = () => {
  return '/login/';
}

export const accountSettingsPath = () => {
  return '/account-settings/';
}

export const requestPasswordReset = () => {
  return '/user/password/reset/';
}

export const verifyPasswordReset = (id: string) => {
  return `/user/password/reset/token/${id}/`;
}



