import { Invoice } from "types";

interface InvoicePathProps {
  print?: boolean
}

export const invoicePath = (invoice: Invoice, opts?: InvoicePathProps) => {
  if (opts?.print) {
    return `/invoice/${invoice.id}/print/`;
  }
  return `/invoice/${invoice.id}/`;
}