import { API_URL_BASE } from 'requests/base';

export * from './admin';
export * from './account';
export * from './billedEntity';
export * from './cart';
export * from './client';
export * from './invoice';
export * from './payment';
export * from './statement';
export * from './user';



export const dashboardPath = () => {
  return '/dashboard/';
}

export const pdfPath = (url: string) => {
  const domain = `${window.location.protocol}//${window.location.host}`
  return `${API_URL_BASE}/print/pdf?url=${domain}${url}`;
}