import { addQuery, CrudPathProps } from "routes/crud";
import { Cart, Client, Invoice, Maybe, Payment } from "types";



export interface ClientInvoicePathProps extends CrudPathProps {
  invoice?: Invoice
  preview?: boolean
}

export const clientInvoicePath = (client: Client, opts?: ClientInvoicePathProps) => {
  if (!client) {
    return '';
  }
  let url = ''
  if (opts?.invoice) {
    if (opts.update) {
      url = `/clients/${client.id}/invoices/${opts.invoice.id}/edit`;
    } else if (opts.preview) {
      url = `/clients/${client.id}/invoices/${opts.invoice.id}/preview`;
    } else {
      url = `/clients/${client.id}/invoices/${opts.invoice.id}/`;    
    }
  } else {
    if (opts?.create) {
      url = `/clients/${client.id}/invoices/new`;
    } else {
      url = `/clients/${client.id}/invoices/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query)
  }
  return url;
}