import { Payment } from "types";

interface PaymentPathProps {
  print?: boolean
}

export const paymentPath = (payment: Payment, opts?: PaymentPathProps) => {
  if (opts?.print) {
    return `/payment-receipt/${payment.id}/print/`;
  }
  return `/payment-receipt/${payment.id}/`;
}