import { CrudPathProps, addQuery } from "routes/crud";
import { Cart, Client, Maybe, Payment, Policy } from "types";



export interface ClientPoliciesPathProps extends CrudPathProps {
  policy?: Policy
}

export const clientPoliciesPath = (client: Client, opts?: ClientPoliciesPathProps) => {
  if (!client) {
    return '';
  }
  let url = ''
  if (opts?.policy) {
    if (opts.update) {
      url =  `/clients/${client.id}/policies/${opts.policy.id}/edit`;    
    } else {
      url =  `/clients/${client.id}/policies/${opts.policy.id}/`;    
    }
  } else {
    if (opts?.create) {
      url =  `/clients/${client.id}/policies/new`;
    } else {
      url =  `/clients/${client.id}/policies/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query)
  }
  return url;
}