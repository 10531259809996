import { Cart, Client, EndInsured, Maybe, Product } from "types";
import { CrudPathProps } from "../crud";

export * from './client-agency';
export * from './client-banks';
export * from './client-configuration';
export * from './client-end-insured';
export * from './client-invoices';
export * from './client-payments';
export * from './client-policies';
export * from './client-report';
export * from './client-settlements';

interface ClientCrudPathProps extends CrudPathProps {
  client?: Maybe<Client>,
  configuration?: boolean
}

export const clientPath = (opts?: ClientCrudPathProps) => {
  if (opts?.client) {
    if (opts?.configuration) {
      return `/clients/${opts.client.id}/configuration/`;
    } else if (opts?.update) {
      return `/clients/${opts.client.id}/edit/`;
    } else {
      return `/clients/${opts.client.id}/`;
    }
  } else {
    if (opts?.create) {
      return '/clients/new/';
    } else {
      return '/clients/';
    }
  }
}





interface ClientCartPathProps {
  cart?: Cart
  create?: boolean
  update?: boolean
}

export const clientCartPath = (client: Client, opts?: ClientCartPathProps) => {
  if (!client) {
    return '';
  }
  if (opts?.create) {
    return `/clients/${client.id}/carts/new/`;
  } else if (opts?.cart) {
    if (opts.update) {
      return `/clients/${client.id}/carts/${opts.cart.id}/edit`;    
    } else {
      return `/clients/${client.id}/carts/${opts.cart.id}/`;    
    }
  } else {
    return `/clients/${client.id}/carts/`;
  }
}