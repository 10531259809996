import { CrudPathProps } from "routes/crud";
import { Cart, Client, EndInsured, Maybe, PastDueCorrespondence, PastDueItem } from "types";

export interface ClientInsuredCrudPathProps extends CrudPathProps {
  endInsured?: Maybe<EndInsured>
}

export const clientInsuredPath = (client: Client, opts?: ClientInsuredCrudPathProps) => {
  if (opts?.endInsured?.id) {
    if (opts?.update) {
      return `/clients/${client.id}/insured/${opts.endInsured.id}/edit/`;
    } else {
      return `/clients/${client.id}/insured/${opts.endInsured.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/insured/new/`;
    } else {
      return `/clients/${client.id}/insured/`;
    }
  }
}

interface ClientInsuredCartCrudPathProps extends CrudPathProps {
  cart?: Maybe<Cart>
  review?: boolean
}

export const clientInsuredCartPath = (client: Client, endInsured: EndInsured, opts?: ClientInsuredCartCrudPathProps) => {
  if (opts?.cart) {

    if (opts?.update) {
      return `/clients/${client.id}/insured/${endInsured.id}/carts/${opts.cart.id}/edit/`;
    } else if (opts?.review) {
      return `/clients/${client.id}/insured/${endInsured.id}/carts/${opts.cart.id}/review/`;
    } else {
      return `/clients/${client.id}/insured/${endInsured.id}/carts/${opts.cart.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/insured/${endInsured.id}/carts/new/`;
    } else {
      return `/clients/${client.id}/insured/${endInsured.id}/carts/`;
    }
  }
}


interface ClientInsuredCorrespondenceCrudPathProps extends CrudPathProps {
  correspondence?: Maybe<PastDueCorrespondence>
  review?: boolean
}

export const clientInsuredCorrespondencePath = (client: Client, endInsured: EndInsured, opts?: ClientInsuredCorrespondenceCrudPathProps) => {
  if (opts?.correspondence) {
    if (opts?.update) {
      return `/clients/${client.id}/insured/${endInsured.id}/correspondence/${opts.correspondence.date}/edit/`;
    } else if (opts?.review) {
      return `/clients/${client.id}/insured/${endInsured.id}/correspondence/${opts.correspondence.date}/review/`;
    } else {
      return `/clients/${client.id}/insured/${endInsured.id}/correspondence/${opts.correspondence.date}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/insured/${endInsured.id}/correspondence/new/`;
    } else {
      return `/clients/${client.id}/insured/${endInsured.id}/correspondence/`;
    }
  }
}
