import { CrudPathProps } from "routes/crud";
import { Client, Maybe, Payment, Settlement } from "types";


interface ClientSettlementCrudPathProps {
  settlement?: Maybe<Settlement>
  settlement_id?: string
}

export const clientSettlementsPath = (client: Client, opts?: ClientSettlementCrudPathProps) => {
  if (!client) {
    return '';
  }
  if (opts?.settlement?.id || opts?.settlement_id) {
    return `/clients/${client.id}/settlements/${opts.settlement?.id || opts.settlement_id}/`;
  } else {
    return `/clients/${client.id}/settlements/`;
  }
}