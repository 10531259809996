
export interface Query {
  [x: string]: any
}

export interface CrudPathProps {
  create?: boolean
  update?: boolean
  query?: Query
}


export const addQuery = (url: string, query: Query) => {
  let queryItems: Array<string> = [];
  Object.keys(query).forEach((k)=>{
    if (query[k] !== true) {
      queryItems.push(`${k}=${query[k]}`);
    } else {
      queryItems.push(k);
    }
  })
  return `${url}?${queryItems.join('&')}`
}